import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer

  ){
    this.matIconRegistry.addSvgIcon(
      `paillot`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/img/paillote.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `cabane`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/img/cabane.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `parasol`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/img/umbrella.svg`)
    );
  }
  title = 'coucou-backoffice';
}
